// App.js
import React, { useState, useEffect } from "react";
import "./App.css";


const searchEngines = [
  { name: "Google", icon: "https://tomy.cc/assets/img/google.svg", url: "https://www.google.com/search?q=" },
  { name: "Bing", icon: "https://tomy.cc/assets/img/bing.svg", url: "https://www.bing.com/search?q=" },
  { name: "DuckDuckGo", icon: "https://tomy.cc/assets/img/duckduckgo.svg", url: "https://duckduckgo.com/?q=" },
  { name: "Youtube", icon: "https://tomy.cc/assets/img/youtube.svg", url: "https://www.youtube.com/results?search_query=" },
  { name: "Reddit", icon: "https://tomy.cc/assets/img/reddit.svg", url: "https://www.reddit.com/search/?q=" },
  { name: "Twitter", icon: "https://tomy.cc/assets/img/x-twitter.svg", url: "https://twitter.com/search?q=" },
  { name: "Github", icon: "https://tomy.cc/assets/img/github.svg", url: "https://github.com/search?q=" },
  { name: "Twitch", icon: "https://tomy.cc/assets/img/twitch.svg", url: "https://www.twitch.tv/search?term=" }
];

function App() {
  const [query, setQuery] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);

  useEffect(() => {
    const storedHistory = localStorage.getItem("searchHistory");
    if (storedHistory) {
      setSearchHistory(JSON.parse(storedHistory));
    }
  }, []);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setQuery(inputValue);
    setShowOptions(inputValue.length > 0);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      performSearch(searchEngines[0].url); // Perform search in Google
    }
  };
  const performSearch = (url) => {
    const encodedQuery = encodeURIComponent(query);
    const updatedHistory = [...searchHistory, { query, url }];
    setSearchHistory(updatedHistory);
    localStorage.setItem("searchHistory", JSON.stringify(updatedHistory));
    window.open(url + encodedQuery);
  };

  const handleOptionClick = (url) => {
    performSearch(url);
    setShowOptions(true); // Show search options after performing search
  };

  const currentYear = new Date().getFullYear();

  const handleClearHistory = () => {
    setSearchHistory([]);
    localStorage.removeItem("searchHistory");
  };

  return (

    <div className="header">
    {/* Top Image */}
    <a href="https://tomy.cc/" target="_blank" rel="noopener noreferrer">
    <img src="https://tomy.cc/assets/img/tomy-cc.png" alt="TomYcc logo" className="top-image" />
    </a>

    <div className="app-container">
      <div className="search-container">
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Enter your search query"
          required
          className="search-input"
        />
        {showOptions && (
          <div className="search-options">
            <ul>
            {searchEngines.map((engine, index) => (
  <li key={index} onClick={() => handleOptionClick(engine.url)}>
    <img src={engine.icon} alt={engine.name} className="icon" />
    <span>{engine.name}</span>
  </li>
))}
            </ul>
          </div>
        )}
      </div>
      <div className="history-container">
        <div className="history-header">
          <h2>Search History</h2>
          <button className="clear-button" onClick={handleClearHistory}>Clear</button>
        </div>
        <ul>
          {/* Filter out duplicate search entries */}
          {searchHistory.reduce((uniqueHistory, item) => {
            const isDuplicate = uniqueHistory.some(
              (entry) => entry.query === item.query && entry.url === item.url
            );
            if (!isDuplicate) {
              uniqueHistory.push(item);
            }
            return uniqueHistory;
          }, []).map((item, index) => (
            <li key={index}>
              <a href={item.url + encodeURIComponent(item.query)} target="_blank" rel="noopener noreferrer">{item.query}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
    <h2 className="copyright">© {currentYear} TomY.cc. No affiliation with any companies or projects.</h2>
  </div>
  
  );
}

export default App;
